import React from "react";
import Logo from "./Logo";
import "../style/who-we-are.scss";
import "../style/draggablecard.scss";
import "../style/NewSlider.scss";
import "../style/starrySky.scss";
import NewContact from "./NewContact";
import StarrySky2d from "./StarrySky2d";
import { OnceContext, TextureContext } from "../context/OnceContext";
import { whoWeAreTexts } from "../copy/videotexts";
import { whowearePictograms } from "../copy/pictograms";

import { Card, Card1, Card2, Dragger } from "./DraggableCard";
import { isDesktop, isLaptop } from "../util/DeviceType";

import NewOurWork from "./NewOurWork";

import history from "../routes/history";
import TextBox from "./TextBox";
import Pictograms from "./Pictograms";
import { isPhone } from "../util/DeviceType";
import { throttle } from "../util/UIHelper";

export class WhoWeAre extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.containerRef = React.createRef();
    this.resetAllCards = this.resetAllCards.bind(this);
    this.state = {
      showContact: false,
      activeId: 0,
      isMobile: false,
      showSwipe: true,
      swipeStart: 0,
      swipeEnd: 0,
      diffX: 0,
      diffY: 0,
      dragging: false,
      showUI: false,
      hideUI: false,
      contactButtonTop: 0,
      showContact: false,
      lastCardBottom: 0,
      activeText: 1,
      value: 0,
      startY: null,
      direction: null,
      pageId: "who-we-are",
      disableScrolling: null,
    };

    this.handleChange = (event) => {
      this.setState({ value: event.target.value });
    };

    this.handleScroll = (event) => {
      const direction = event.deltaY > 0 ? 1 : -1; // Positive value means scrolling down, negative means scrolling up
      if (direction > 0) {
        this.handleOpeningNextPage();
      } else if (direction < 0) {
        this.handleOpeningNextPageUP();
      }
    };

    this.throttledScroll = throttle(this.handleScroll, 1500);

    if (!isPhone()) {
      window.addEventListener("wheel", this.throttledScroll);
      //window.addEventListener("scroll", this.handleScroll);
    }
    // window.addEventListener("touchmove", this.handleScroll, { passive: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableScrolling !== this.state.disableScrolling) {
      console.log("trigered");
      setTimeout(() => {
        this.setState((prevState, prevProps) => {
          if (prevState.disableScrolling) {
            return { disableScrolling: false };
          }
          return null;
        });
      }, 1000);
    }
    if (prevState.value !== this.state.value) {
      if (this.state.value === 100) {
        this.setState({ activeText: 0 });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          //window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/solutions");
          }, 1501);
        }, 1000);
      }
    }
  }

  toggleActiveText = (id) => {
    this.setState({ activeText: id });
  };

  handleShowContact = () => {
    this.setState(function (prevState, props) {
      return { showContact: !prevState.showContact };
    });
  };

  calculateFormTop() {
    const form = document.querySelector(".new-contact");
    const showFormButton = document.querySelector(".show-contact-button");

    const top = form.getBoundingClientRect().top;
    return top;
  }

  functionToPass = () => {
    document.getElementById("who-we-are").style.opacity = "0";
    setTimeout(() => {
      history.push("/selection-page");
    }, 1200);
  };

  componentDidMount() {
    //this.props.handleMaskFade(false);
    document.getElementById("who-we-are").style.opacity = "1";
    this.setState({ isMobile: this.context.matches, showUI: true });

    // window.addEventListener("resize", () => {});
    const top = this.calculateFormTop();

    if (top) {
      this.setState({ contactButtonTop: top });
    }
    document.body.addEventListener("click", this.resetAllCards);
  }

  componentWillUnmount() {
    this.setState({
      disableScrolling: null,
    });

    document.body.removeEventListener("click", this.resetAllCards);
    window.removeEventListener("wheel", this.throttledScroll);
    // window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
  }

  resetAllCards(e) {
    if (e.target) {
      if (e.target.id === "dragger-area") {
        const cards = document.querySelector(".dragger-area").children;
        const box = document.querySelector(".who-we-are-block");
        Array.from(cards).forEach((el) => {
          if (el.id !== "who-we-are-block") {
            el.style.left = "unset";
          }
          el.style.zIndex = -10;
          el.style.transform = "none";
          el.classList.remove("glass");
          el.querySelector(".layers").style.display = "none";
        });

        box.style.zIndex = 100;
        box.style.transform = "none";
        box.classList.remove("glass");
        box.querySelector(".layers").style.display = "none";
      }
    }
  }

  showContact = () => {
    if (history.location.pathname === "/who-we-are" && !this.state.showContact) {
      const cards = document.querySelectorAll(".card");
      for (const card of cards) {
        card.style.left = 0;
      }
    }
    this.setState((prevState) => ({
      showContact: !prevState.showContact,
    }));
  };

  handleBoxClick = (e) => {
    const cards = document.querySelector(".dragger-area").children;
    const box = document.getElementById("who-we-are-block");

    Array.from(cards).forEach((el) => {
      if (el.id !== "who-we-are-block") {
        el.style.left = "unset";
        el.style.transform = "none";
        el.style.zIndex = -10;

        el.classList.remove("glass");
        el.querySelector(".layers").style.display = "none";
      }
    });

    e.target.style.zIndex = 100;

    e.target.style.transform = "rotateY(30deg)";
    box.classList.add("glass");
    box.querySelector(".layers").style.display = "block";
  };

  handleBoxTilt = (e) => {
    const evt = e.type === "touchmove" ? e.changedTouches[0] : e;
    if (evt.target.classList.contains("glass")) {
      const bcr = evt.target.getBoundingClientRect();
      const x = Math.min(1, Math.max(0, (evt.clientX - bcr.left) / bcr.width));
      const y = Math.min(1, Math.max(0, (evt.clientY - bcr.top) / bcr.height));
      const tiltX = 75 / 2 - x * 75;
      const tiltY = y * 75 - 75 / 2;
      evt.target.style.transform = `rotateX(${tiltY}deg) rotateY(${tiltX}deg) `;
    }
  };

  ///////////////
  //Slider Functions
  handleMouseDown = (e) => {
    this.setState({
      startY: e.clientY,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      } else if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleMouseUp = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  handleTouchStart = (e) => {
    this.setState({
      startY: e.touches[0].clientY,
    });
  };

  handleTouchMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.touches[0].clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      } else if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleOpeningNextPage() {
    console.log(this.state.disableScrolling);
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 0) {
        this.setBulletValue(33);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 33) {
        this.setBulletValue(66);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 66) {
        this.setBulletValue(100);
        this.setState({
          activeText: 0,
        });

        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/solutions");
          }, 1501);
        }, 1000);
      }
    }
  }

  handleClickCircle(bulletValue, activeText) {
    if (isPhone()) {
      if (!this.state.disableScrolling) {
        this.setBulletValue(bulletValue);
        this.setState({
          activeText: activeText,
        });
        // if (this.state.value === 66) {
        //   this.setState({ activeText: 0 });
        //   setTimeout(() => {
        //     this.state.currentScreenState = 1;

        //     // this.props.handleMaskFade(true);
        //     this.props.handleMaskFade(true);
        //     //window.removeEventListener("wheel", this.handleScroll);
        //     // window.removeEventListener("scroll", this.handleScroll);
        //     // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

        //     setTimeout(() => {
        //       history.push("/solutions");
        //     }, 1501);
        //   }, 1000);
        // }
      }
    }
  }

  handleOpeningNextPageUP() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 33) {
        this.setBulletValue(0);
        this.setState({
          value: 0,
          activeText: 1,
        });
      } else if (this.state.value == 66) {
        this.setBulletValue(33);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 100) {
        this.setBulletValue(66);
        this.setState({
          activeText: 3,
        });
      }
    }
  }

  handleTouchEnd = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  setBulletValue = (val) => {
    this.setState((prevState, prevProps) => {
      if ((!prevState.value != val || val == 0) && !prevState.disableScrolling) {
        return { value: val };
      }
      return null;
    });

    this.setState((prevState, prevProps) => {
      if (!prevState.disableScrolling) {
        return { disableScrolling: true };
      }
      return null;
    });
  };
  //////////
  render() {
    const { showUI, hideUI, value } = this.state;
    const phoneQuery = this.context;

    return (
      <div id="who-we-are">
        {/* Scroll Bar */}
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div className="pipe-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={value}
                onChange={this.handleChange}
                className="pipe-input"
              />
              <div className="pipe"></div>
              <div className="fill" style={{ height: `${value}%` }} />
              <div
                className="pulsating-circle"
                style={{ top: `${value}%` }}
                // onMouseDown={this.handleMouseDown}
                // onMouseMove={this.handleMouseMove}
                // onMouseUp={this.handleMouseUp}
                // onTouchStart={this.handleTouchStart}
                // onTouchMove={this.handleTouchMove}
                // onTouchEnd={this.handleTouchEnd}
              />
              <div className="input-start">
                <p>Start</p>
              </div>
              <div className="input-end">
                <p>Next</p>
              </div>
              <div className="line-zero" onClick={() => this.handleClickCircle(0, 1)}></div>
              <div className="line-first" onClick={() => this.handleClickCircle(33, 2)}></div>
              <div className="line-second" onClick={() => this.handleClickCircle(66, 3)}></div>
              <div className="line-third" onClick={() => this.handleClickCircle(100, 0)}></div>
            </div>
          </div>
        </div>

        {/* Starry sky */}
        <div>
          <div className="reactCanvas" style={{ position: "absolute", top: "0vh", zIndex: "-50" }}>
            <canvas id="space"></canvas>
            <div id="w"></div>
          </div>
        </div>

        {/* <div className="whoweare-cards" ref={this.containerRef}>
          <Dragger>
            {(onStart, onEnter, onLeave, active) => {
              return (
                <>
                  <Card onStart={onStart} onEnter={onEnter} onLeave={onLeave} active={active}>
                    <h1 className="card-title">vision</h1>
                    <p className="card-description">
                      By being leaders in providing solutions, our vision is to make imagination a new reality,
                      realizing our client's ideas with our expert knowledge and technology. 
                    </p>
                  </Card>

                  <Card1 onStart={onStart} onEnter={onEnter} onLeave={onLeave} active={active}>
                    <h1 className="card-title">mission</h1>
                    <p className="card-description">
                      As pioneers of innovative solutions, we create harmony between business and technology, securing
                      your triumph in today's competitive market. 
                    </p>
                  </Card1>
                  <Card2 onStart={onStart} onEnter={onEnter} onLeave={onLeave} active={active}>
                    <h1 className="card-title" style={{ marginBottom: "5px" }}>
                      strategy
                    </h1>
                    <p className="card-description">
                      We ensure project success and maximize our clients' profits by guiding them through the entire
                      project lifecycle, from defining their needs to project delivery and management. 
                    </p>
                  </Card2>

                  <div
                    id="who-we-are-block"
                    className="who-we-are-block"
                    onClick={this.handleBoxClick}
                    onMouseMove={this.handleBoxTilt}
                    onTouchMove={this.handleBoxTilt}
                  >
                    <h1 className="who-we-are-block-title">our organization</h1>
                    <div className="who-we-are-block-description">
                      <p> Harmonity Group is a leading provider of IT and Management Services.</p>
                      <p>Nothing less than the best for your business.</p>
                      <p>
                        We deliver exceptional results through our 360˚ structure and dedicated team of professionals.
                      </p>
                      <p>We work closely with our clients,</p>
                      <p>providing expertise and ongoing support.</p>
                    </div>
                    <div className="layers">
                      <div className="layer"></div>
                      <div className="layer"></div>
                      <div className="layer"></div>
                      <div className="layer"></div>
                      <div className="layer"></div>
                    </div>
                  </div>
                </>
              );
            }}
          </Dragger>
        </div> */}
        <TextBox
          noGlassBackground={true}
          activeText={this.state.activeText}
          activeTextContent={whoWeAreTexts[this.state.activeText]}
          toggleActiveText={this.toggleActiveText}
          showInterface={false}
        />
        <Pictograms
          toggleActiveText={this.toggleActiveText}
          activeText={this.state.activeText}
          pictograms={whowearePictograms}
          bulletValue={this.setBulletValue}
          pageId={this.state.pageId}
        />
        <Logo
          passedFunction={this.functionToPass}
          url={"/assets/sajtLogos/Harmonity_Group_Logo_White_Transparent.png"}
        />

        <NewContact />
        <NewOurWork />
        <StarrySky2d />
      </div>
    );
  }
}

WhoWeAre.contextType = TextureContext;
