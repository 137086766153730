import React, { useState, useEffect, useContext, useRef } from "react";
import NewContact from "./NewContact";
import history from "../routes/history";
import "../style/selection.scss";
import KockaSelectionPage from "./KockaSelectionPage";
import New3dCube from "./New3dCube";
import NewOurWork from "./NewOurWork";
import { Stage } from "@pixi/react";
import { isPhone } from "../util/DeviceType";

import Logo from "./Logo";

import { TextureContext } from "../context/OnceContext";
import { useMediaQuery } from "@react-hook/media-query";
import StarrySky2d from "./StarrySky2d";

class HomeButton extends React.Component {
  onHover = () => this.props.onHover(this.props.buttonId);
  onClick = () => this.props.onClick(this.props.buttonId);
  onTouch = () => this.props.onHover(this.props.buttonId);

  render() {
    const { title, activeId, buttonId } = this.props;

    let className = "home-btn";
    if (this.props.activeId === this.props.buttonId) {
      className += " home-btn-active";
    }

    return (
      <button
        style={{ top: `${this.props.top}` }}
        onMouseOver={this.onHover}
        onClick={this.onClick}
        onTouchStart={this.onTouch}
        className={className}
      >
        {title}
      </button>
    );
  }
}

export default function Selection(props) {
  const [activeId, setActiveId] = useState(null);
  const phoneQuery = useMediaQuery("only screen and (max-width: 481px)");
  const [showUI, setShowUI] = useState(false);
  const [hideUI, setHideUI] = useState(false);
  // const [textures, setTextures] = useState([]);
  const [mobileBtnsTop, setMobileBtnsTop] = useState(null);
  const [contactButtonTop, setContactButtonTop] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const [isWarping, setIsWarping] = useState(false);
  const canvasRef2D = useRef(null);
  let animate = true;
  let stars = [];
  let star;
  let i;
  let canvas;
  let c;
  //  const tc = useContext(TextureContext);

  const handleClickMask = (isTrue) => {
    props.handleMaskFade(isTrue);
  };

  useEffect(() => {
    document.getElementById("selection").style.opacity = "1";
    canvas = canvasRef2D.current;
    if (!canvas) {
      return;
    }

    //createStarrySky();

    c = canvas.getContext("2d");

    let numStars = 1900;
    let radius = "0." + Math.floor(Math.random() * 9) + 1;
    let focalLength = canvas.width * 2;
    let warp = 0;
    let centerX, centerY;

    let isWarping = false;

    initializeStars();

    function executeFrame() {
      if (animate) requestAnimationFrame(executeFrame);
      moveStars();
      drawStars();
    }

    function initializeStars() {
      centerX = canvas.width / 2;
      centerY = canvas.height / 2;

      stars = [];
      for (i = 0; i < numStars; i++) {
        star = {
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          z: Math.random() * canvas.width,
          o: "0." + Math.floor(Math.random() * 99) + 1,
        };
        stars.push(star);
      }
    }

    function moveStars() {
      if (!star) return;
      for (i = 0; i < numStars; i++) {
        star = stars[i];
        star.z--;

        if (star.z <= 0) {
          star.z = canvas.width;
        }
      }
    }

    function drawStars() {
      var pixelX, pixelY, pixelRadius;
      if (!canvas) return;

      // Resize to the screen
      if (canvas.width != window.innerWidth || canvas.width != window.innerWidth) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        initializeStars();
      }
      if (isWarping == 0) {
        c.fillStyle = "rgba(0,10,20,1)";
        c.fillRect(0, 0, canvas.width, canvas.height);
      }
      c.fillStyle = "rgba(209, 255, 255, " + radius + ")";
      for (i = 0; i < numStars; i++) {
        star = stars[i];

        pixelX = (star.x - centerX) * (focalLength / star.z);
        pixelX += centerX;
        pixelY = (star.y - centerY) * (focalLength / star.z);
        pixelY += centerY;
        pixelRadius = (isPhone() ? 0.5 : 1) * (focalLength / star.z);

        c.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
        c.fillStyle = "rgba(209, 255, 255, " + star.o + ")";
        //c.fill();
      }
    }

    function startWarping() {
      isWarping = isWarping == 1 ? 0 : 1;
      c.clearRect(0, 0, canvas.width, canvas.height);
      executeFrame();
    }

    document.querySelectorAll(".home-btn").forEach((but) => {
      but.addEventListener("click", startWarping);
    });

    executeFrame();

    return () => {
      console.log("return");
      // console.log(animate)
      animate = null;
      canvasRef2D.current = null;
      stars = [];
      star = null;
      i = null;
      canvas = null;
      c = null;
      document.removeEventListener("click", startWarping);
    };
  }, []);

  const onButtonHover = (id) => {
    setActiveId(id);
  };

  const onClick = (url) => {
    document.getElementById("selection").style.opacity = "0";
    if (url != "/") {
      setIsWarping(true);
      //handleClickMask(true);
    }
    setTimeout(() => {
      history.push(url);
    }, 1200);
  };

  const handleShowUI = () => {
    setShowUI(true);
  };

  useEffect(() => {
    const top = calculateFormTop();

    if (top) {
      setContactButtonTop(top);
    }
  }, []);

  const handleShowContact = () => {
    setShowContact((prevState) => !prevState);
  };

  function calculateFormTop() {
    const form = document.querySelector(".new-contact");

    const top = form.getBoundingClientRect().top;
    return top;
  }

  return (
    <div id="selection">
      <div id="selection-content">
        <div
          className="home-btns"
          // style={{
          //   top: phoneQuery ? `${mobileBtnsTop + 37}px` : "35%",
          // }}
        >
          <HomeButton
            title={"who we are"}
            onHover={() => onButtonHover(0)}
            onClick={() => {
              onClick("/who-we-are");
            }}
            buttonId={0}
            activeId={activeId}
          ></HomeButton>
          <HomeButton
            title={"solutions"}
            onHover={() => onButtonHover(1)}
            onClick={() => {
              onClick("/solutions");
            }}
            buttonId={1}
            activeId={activeId}
          ></HomeButton>

          <HomeButton
            title={"business consulting"}
            onHover={() => onButtonHover(2)}
            onClick={() => {
              onClick("/business-consulting");
            }}
            buttonId={2}
            activeId={activeId}
          ></HomeButton>

          <HomeButton
            title={"academy"}
            onHover={() => onButtonHover(3)}
            onClick={() => {
              onClick("/academy");
            }}
            buttonId={3}
            activeId={activeId}
          ></HomeButton>
          <HomeButton
            title={"r&d"}
            onHover={() => onButtonHover(4)}
            onClick={() => {
              onClick("/research-and-development");
            }}
            buttonId={4}
            activeId={activeId}
          ></HomeButton>
          <HomeButton
            title={"pixels2pixels"}
            onHover={() => onButtonHover(5)}
            onClick={() => {
              onClick("/pixels-2-pixels");
            }}
            buttonId={5}
            activeId={activeId}
          ></HomeButton>
          {/* <HomeButton
            title={"our work"}
            onHover={() => onButtonHover(6)}
            onClick={() => {
              onClick("/our-work");
              //handleClickMask(true);
            }}
            buttonId={6}
            activeId={activeId}
          ></HomeButton> */}
        </div>
        <Logo
          url={"/assets/sajtLogos/Harmonity_Group_Logo_White_Transparent.png"}
          passedFunction={() => {
            onClick("/");
          }}
        />

        {/* <Stage width={window.innerWidth} height={window.innerHeight} options={{ resizeTo: window }}>
          <KockaSelectionPage id={activeId} handleShowUI={handleShowUI} textures={textures} />
        </Stage> */}

        <NewContact />
        <New3dCube activeId={activeId} />
        <div>
          <div className="reactCanvas" style={{ position: "absolute", top: "0vh", zIndex: "-50" }}>
            <canvas id="space" ref={canvasRef2D}></canvas>
            <div id="w"></div>
          </div>
        </div>

        {/* <StarrySky2d /> */}
      </div>

      {/* <NewOurWork/> */}
    </div>
  );
}
