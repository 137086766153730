import React from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import "../style/newcontact.scss";

import callPng from "./../assets//images/call.png";
import locationPng from "./../assets//images/location.png";
import globalPng from "./../assets//images/global.png";
import instagramPng from "./../assets//images/Instagram.png";
import facebookPng from "./../assets//images/Facebook.png";
import inPng from "./../assets//images/in.png";
import twitterPng from "./../assets//images/Twitter.png";
import youtubePng from "./../assets//images/YT.png";
import { OnceContext } from "../context/OnceContext";

// import { leaveScene, mobileViewChangeSlide } from "../scenes/ContactScene";

import axios from "axios";
import MailSent from "./mailsent";

import { isDesktop } from "../util/DeviceType";

export default class NewContact extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = (event) => {
      if (event.wheelDelta > 0) return;
      //this.handleMoveScroll();
    };

    if (isDesktop()) window.addEventListener("wheel", this.handleScroll);
  }

  // handleMoveScroll() {
  //   scrollTo("650px");
  //   setTimeout(() => {
  //     leaveScene();
  //   }, 500);
  // }

  // passedFunction = () => {
  //   leaveScene();
  // };

  state = {
    activeId: 0,
    showContact: false,
    isMobile: false,
    showSwipe: true,
    swipeStart: 0,
    swipeEnd: 0,
    email: "",
    name: "",
    message: "",
    formErrors: { email: "", name: "", message: "" },
    emailValid: false,
    nameValid: false,
    messageValid: false,
    formValid: false,
    mailSent: false,
    mailSending: false,
    mailSentServerError: null,
  };

  componentDidMount() {
    this.setState({ isMobile: this.context.matches });
  }

  handleSwipeStart = (e) => {
    this.setState({ swipeStart: e.targetTouches[0].clientX, swipeEnd: 0 });
  };

  handleSwipeEnd = (e) => {
    this.setState({ swipeEnd: e.targetTouches[0].clientX });
  };

  handleTouchEnd = () => {
    if (!this.state.swipeEnd || !this.state.swipeStart) return;
    const isLeft = this.state.swipeEnd < this.state.swipeStart;
    const isRight = this.state.swipeEnd > this.state.swipeStart;
    if (isLeft) {
      let newActiveId;
      if (this.state.activeId === 2) {
        this.setState({ activeId: 0 });
        newActiveId = 0;
      } else {
        newActiveId = this.state.activeId + 1;
        this.setState({ activeId: newActiveId });
      }
      // mobileViewChangeSlide(newActiveId);
    }
    if (isRight) {
      let newActiveId;
      if (this.state.activeId === 0) {
        this.setState({ activeId: 2 });
        newActiveId = 2;
      } else {
        newActiveId = this.state.activeId - 1;
        this.setState({ activeId: newActiveId });
      }
      // mobileViewChangeSlide(newActiveId);
    }
  };

  handleFormSubmit = (e) => {
    const userData = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    e.preventDefault();

    if (this.state.formValid) {
      this.setState({ mailSending: true });
      axios
        .post("https://harmonity-group.com/nodemailer/send", userData)
        .then((res) => {
          if (res.status === 200) {
            this.clearForm();
            this.setState({ mailSent: true, mailSending: false, mailSentServerError: null });
          }
        })
        .catch((err) => {
          this.setState({ mailSentServerError: true, mailSending: false });
        });
    } else {
      this.validateFieldsOnSubmit();
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateField(e.target.name, e.target.value);
    });
  }
  handleShowContact = () => {
    this.setState((prevState) => {
      return { showContact: !prevState.showContact };
    });
    // setShowContact((prevState) => !prevState);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let messageValid = this.state.messageValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " Email is invalid";
        break;
      case "name":
        nameValid = value.length >= 3;
        fieldValidationErrors.name = nameValid ? "" : "Name is too short. Minimum 3 symbols";
        break;
      case "message":
        messageValid = value.length > 10;
        fieldValidationErrors.message = messageValid ? "" : "Message is too short. Minimum 10 symbols";
        break;
      default:
        break;
    }
    this.setState(
      { formErrors: fieldValidationErrors, emailValid: emailValid, nameValid: nameValid, messageValid: messageValid },
      this.validateForm
    );
  }

  validateFieldsOnSubmit() {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let messageValid = this.state.messageValid;

    emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    fieldValidationErrors.email = emailValid ? "" : "Email is invalid";
    nameValid = this.state.name.length >= 3;
    fieldValidationErrors.name = nameValid ? "" : " Name is too short. Minimum 3 symbols";
    messageValid = this.state.message.length > 10;
    fieldValidationErrors.message = messageValid ? "" : "Message is too short. Minimum 10 symbols";

    this.setState(
      { formErrors: fieldValidationErrors, emailValid: emailValid, nameValid: nameValid, messageValid: messageValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid && this.state.nameValid && this.state.messageValid });
  }

  clearForm() {
    this.setState({ name: "", email: "", message: "" });
  }

  removePopup = () => {
    this.setState({ mailSent: false, mailSentServerError: null });
  };

  render() {
    const { activeId, isMobile } = this.state;

    return (
      <div className={this.state.showContact ? "new-contact contact-active" : "new-contact "}>
        {this.state.mailSent && <MailSent removePopup={this.removePopup} />}
        {this.state.mailSent && <div className="overlay"></div>}
        {this.state.mailSentServerError && <MailSent removePopup={this.removePopup} error={true} />}
        {this.state.mailSentServerError && <div className="overlay"></div>}
        <button
          onClick={this.handleShowContact}
          className={!this.state.showContact ? "show-contact-button" : "show-contact-button-open"}
        >
          Contact
        </button>
        <div className="contact-block">
          <h1 className="contact-title">Contact</h1>
          <p className="description text-regular">
            If you find yourself in need of perfect harmony between your IT and your business contact us.
          </p>
          <form className="contact-form" onSubmit={this.handleFormSubmit}>
            <div className="input-wrapper">
              <input
                type="text"
                name="name"
                placeholder="Name *"
                value={this.state.name}
                onChange={(e) => this.handleChange(e)}
              />
              {this.state.formErrors.name && (
                <p className="validation-error" name="name">
                  {this.state.formErrors.name}
                </p>
              )}
            </div>
            <div className="input-wrapper">
              <input
                name="email"
                placeholder="Email *"
                value={this.state.email}
                onChange={(e) => this.handleChange(e)}
              />
              {this.state.formErrors.email && (
                <p className="validation-error" name="email">
                  {this.state.formErrors.email}
                </p>
              )}
            </div>
            <div className="input-wrapper">
              <textarea
                rows="6"
                cols="50"
                name="message"
                placeholder="Message *"
                value={this.state.message}
                onChange={(e) => this.handleChange(e)}
              ></textarea>
              {this.state.formErrors.message && (
                <p className="validation-error" name="message">
                  {this.state.formErrors.message}
                </p>
              )}
            </div>
            <button type="submit" className="contact-button" disabled={this.state.mailSending}>
              Send message
            </button>
          </form>
          <div className="contact-block-data">
            <div className="contact-block-data-item">
              <img className="contact-icon" src={callPng} />
              <p>+381 62 898 55 53</p>
            </div>
            <a
              href="https://www.google.com/maps/place/Harmonity+Solutions+doo/@44.7945108,20.4842057,15z/data=!4m2!3m1!1s0x0:0xbe7d89e11da2a56e?sa=X&ved=2ahUKEwihpKHi7u78AhWhpYsKHUuqBRwQ_BJ6BAhIEAg"
              className="contact-block-data-item"
              target="_blank"
            >
              <img src={locationPng} />

              <p>Belgrade, Serbia</p>
            </a>
            <div className="contact-block-data-item">
              <img src={globalPng} />
              <p>office@harmonity.com</p>
            </div>
          </div>
          <div className="social-block">
            <a href="https://www.instagram.com/harmonity.group/" target="_blank">
              <img src={instagramPng} />
            </a>
            <a href="https://www.facebook.com/harmonitygroup" target="_blank">
              <img src={facebookPng} />
            </a>
            <a href="https://www.linkedin.com/company/harmonity-solutions/" target="_blank">
              <img src={inPng} />
            </a>
            <a href="https://twitter.com/HarmonityGroup" target="_blank">
              <img src={twitterPng} />
            </a>
            {/* <a href="https://www.youtube.com/" target="_blank">
                <img src={youtubePng} />
              </a> */}
          </div>
        </div>
      </div>
    );
  }
}

NewContact.contextType = OnceContext;
