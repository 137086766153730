// import React from "react";
// import { Link } from "react-router-dom";
// import "../style/research-and-development.scss";
// import rdAnimationMp4 from "../assets/videos/R&D.mp4";
// import rdAnimationMp4R from "../assets/videos/R&D_r.mp4";
// import { uiVisibility, scrollTo, handleSwipeVisibility, handleScrollIconVisibility } from "../util/UIHelper.js";
// import pix2pixPng from "./../assets/images/our-organisation-logos/Harmonity_Pixels2Pixels_Logo_White_Transparent.png";
// import Logo from "./Logo";
// import { OnceContext } from "../context/OnceContext";
// import { HorisontalScrollbar } from "./HorisontalScrollbar";
// import Swipe from "./swipe";
// import Hand from "./hand";
// import NewScrollbar from "./newscrollbar";
// import history from "../routes/history";
// import { isDesktop, isPhone } from "../util/DeviceType";
// import VerticalScrollIcon from "./verticalscrollicon";
import React from "react";

import "../style/business-consulting.scss";
import "../style/newRND.scss";
import "../style/NewSlider.scss";

import Logo from "./Logo";

// import ResearchAndDevelopmentThree from "./ResearchAndDevelopmentThree";
import ResearchAndDevelopment2Three from "./ResearchAndDevelopment2Three";
import ResearchAndDevelopment3Three from "./ResearchAndDevelopment3Three";

//import { changeScene, pointerWheelEventPaused } from "./../scenes/ResearchAndDevelopmentScene";
import history from "../routes/history";

import { isDesktop, isLaptop, isPhone } from "../util/DeviceType";
import videoFile from "../assets/newScenes/videos/randd-new.webm";
import NewContact from "./NewContact";

import { rndPictogram } from "../copy/pictograms";
import { rndTetxts } from "../copy/videotexts";
import Pictograms from "./Pictograms";
import TextBox from "./TextBox";
import { throttle } from "../util/UIHelper";

let setCanvasWidth = 1920 / 1.65;
let setCanvasHeight = 1080 / 1.65;

const VIDEO_PAUSE_TIME = {
  RD: 3,
  NFT: 4.6,
  IOT: 6.9,
  BS: 9.33,
  ES: 12,
  CC: 15.9,
};
const VIDEO_PAUSE_TIME_REVERT = {
  CC: 1.9,
  ES: 5.8,
  BS: 8.4,
  IOT: 10.9,
  NFT: 13.2,
  RD: 14.8,
};
const VIDEO_DELAY = 50;
let videoRD;
let videoRDRevert;
let scrollPaused = true;

export class ResearchAndDevelopment extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      activeId: 0,
      isMobile: false,
      showContact: false,
      showSwipe: true,
      swipeStart: 0,
      swipeEnd: 0,
      currentScreenState: 1,
      isText1Animated: false,
      isText2Animated: false,
      isText3Animated: false,
      text2AnimationCheck: false,
      text3AnimationCheck: false,
      disableScrolling: null,
      value: 0,
      startY: null,
      direction: null,
      pageId: "academy",
      activeText: 1,
      texts: {
        1: `The\u00A0R&D\u00A0entity\u00A0of\u00A0our\u00A0company\u00A0is\u00A0devoted\u00A0to\u00A0investing\u00A0in\u00A0new\u00A0emerging\u00A0tech\u00A0\nand\u00A0continuous\u00A0improvement\u00A0of\u00A0our\u00A0services\u00A0because\u00A0it's\u00A0what\u00A0keeps\u00A0us\u00A0on\u00A0top.`,
        2: `We\u00A0are\u00A0currently\u00A0focusing\u00A0our\u00A0investments\u00A0on\u00A0a\u00A0product\u00A0that\u00A0combines\nnew\u00A0technologies\u00A0-\u00A0VR,\u00A0metahuman\u00A0technology,\u00A0and\u00A0ChatGPT.`,
        3: `The\u00A0top\u00A0is\u00A0where\u00A0you,\u00A0too,\u00A0will\u00A0find\u00A0yourself\u00A0if\u00A0you\u00A0partner\u00A0up\u00A0with\u00A0us.\nEnable\u00A0your\u00A0organization\u00A0to\u00A0drive\u00A0innovation\u00A0and\u00A0create\u00A0value\u00A0for\u00A0your\nbusinesses\u00A0and\u00A0consumers.`,
      },
      shouldFadeOut1: false,
      shouldFadeOut2: false,
      shouldFadeOut3: false,
      isText1AnimatedTimeout: false,
    };

    this.handleChange = (event) => {
      this.setState({ value: event.target.value });
    };

    this.handleScroll = (event) => {
      const direction = event.deltaY > 0 ? 1 : -1; // Positive value means scrolling down, negative means scrolling up

      if (!isPhone()) {
        if (direction > 0) {
          this.handleOpeningNextPage();
        } else if (direction < 0) {
          this.handleOpeningNextPageUP();
        }
      }
      // if (isDesktop()) {
      //   this.handleMoveScroll(event.wheelDelta > 0);
      // } else {
      //   this.handleMoveScroll(event.touches[0].clientY < this.lastY);
      //   this.lastY = event.touches[0].clientY;
      // }
    };

    this.throttledScroll = throttle(this.handleScroll, 1500);
    if (!isPhone()) {
      window.addEventListener("wheel", this.throttledScroll);
      //  window.addEventListener("scroll", this.handleScroll);
    }
    // window.addEventListener("touchmove", this.handleScroll, { passive: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableScrolling !== this.state.disableScrolling) {
      console.log("trigered");
      setTimeout(() => {
        this.setState((prevState, prevProps) => {
          if (prevState.disableScrolling) {
            return { disableScrolling: false };
          }
          return null;
        });
      }, 1000);
    }

    if (prevState.value !== this.state.value) {
      if (this.state.value === 100) {
        this.setState({ activeText: 0 });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          //window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/pixels-2-pixels");
          }, 1501);
        }, 1000);
      }
    }
  }
  fadeOutText = (whichOne) => {
    if (whichOne == 1) {
      this.setState({ shouldFadeOut1: true });
    } else if (whichOne == 2) {
      this.setState({ shouldFadeOut2: true });
    } else if (whichOne == 3) {
      this.setState({ shouldFadeOut3: true });
    }
  };

  toggleActiveText = (id) => {
    this.setState({ activeText: id });
  };

  handleShowContact = () => {
    this.setState(function (prevState, props) {
      return { showContact: !prevState.showContact };
    });
  };

  handleMoveScroll(direction) {
    if (!this.state.disableScrolling) {
      let nextScreenState = this.state.currentScreenState;
      if (direction) {
        nextScreenState--;
      } else {
        nextScreenState++;
      }
      if (nextScreenState > 5) {
        nextScreenState = 5;
      } else if (nextScreenState < 1) {
        nextScreenState = 1;
      }
      if (true) {
        // if (nextScreenState == 2 && !this.state.isText1Animated) {
        //   // const element = document.querySelector(".rndNewN-paragraph");
        //   // this.animateLetters(element, 0.03);
        //   // this.state.disableScrolling = true;
        //   // this.state.isText1Animated = true;
        //   // setTimeout(() => {
        //   //   this.state.disableScrolling = false;
        //   // }, 6000);
        // } else if (nextScreenState == 3 && !this.state.isText2Animated) {
        //   const element2 = document.querySelector(".rndNewN-paragraph2");
        //   this.animateLetters(element2, 0.03);
        //   this.state.disableScrolling = true;
        //   this.state.isText2Animated = true;
        //   this.fadeOutText(1)
        //   setTimeout(() => {
        //     this.state.disableScrolling = false;
        //   }, 6000);
        // } else if (nextScreenState == 4 && !this.state.isText3Animated) {
        //   const element3 = document.querySelector(".rndNewN-paragraph3");
        //   this.animateLetters(element3, 0.03);
        //   this.state.disableScrolling = true;
        //   this.state.isText3Animated = true;
        //   this.fadeOutText(2)
        //   setTimeout(() => {
        //     this.state.disableScrolling = false;
        //   }, 6000);
        // } else
        if (nextScreenState == 5) {
          this.handleOpeningNextPage();
        }
      }
      this.state.currentScreenState = nextScreenState;
    }
  }

  componentWillUnmount() {
    this.setState({
      disableScrolling: null,
    });

    window.removeEventListener("wheel", this.throttledScroll);
    // window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
    //this.stopSound();
  }

  animateLetters(element, delay) {
    // Get the text content of the element and split it into an array of characters
    const text = element.textContent;
    const letters = text.split("");

    // Replace the text content of the element with an array of span elements, one for each letter
    const html = letters
      .map((letter) => (letter === "\n" ? `<br />` : `<span class="letter">${letter}</span>`))
      .join("");
    element.innerHTML = html;

    // Loop through the span elements and add the "animated" class with a delay to each one
    const letterElements = element.querySelectorAll(".letter");
    letterElements.forEach((letter, index) => {
      letter.style.animationDelay = `${index * delay}s`;
      letter.classList.add("animated");
    });
  }

  modifyTextsForMobile() {
    // change the text for mobile portrait mode
    const newTexts = {
      1: `The\u00A0R&D\u00A0entity\u00A0of\u00A0our\u00A0company\u00A0is\u00A0devoted\u00A0to\u00A0investing\u00A0in\u00A0new\u00A0emerging\u00A0tech\u00A0\nand\u00A0continuous\u00A0improvement\u00A0of\u00A0our\u00A0services\u00A0because\u00A0it's\u00A0what\u00A0keeps\u00A0us\u00A0on\u00A0top.`,
      2: `We\u00A0are\u00A0currently\u00A0focusing\u00A0our\u00A0investments\u00A0on\u00A0a\u00A0product\u00A0that\u00A0combines\u00A0\nnew\u00A0technologies\u00A0-\u00A0VR,\u00A0metahuman\u00A0technology,\u00A0and\u00A0ChatGPT.`,
      3: `The\u00A0top\u00A0is\u00A0where\u00A0you,\u00A0too,\u00A0will\u00A0find\u00A0yourself\u00A0if\u00A0you\u00A0partner\u00A0up\u00A0with\u00A0us.\u00A0\nEnable\u00A0your\u00A0organization\u00A0to\u00A0drive\u00A0innovation\u00A0and\u00A0create\u00A0value\u00A0for\u00A0your\u00A0\nbusinesses\u00A0and\u00A0consumers.`,
    };
    this.setState({ texts: newTexts });
  }

  componentDidMount() {
    this.setState({ isMobile: this.context.matches });
    //this.playSound();
    if (window.matchMedia("(max-width: 767px)").matches) {
      this.setState({ isMobile: true });
      this.modifyTextsForMobile();
    }

    // if(!this.state.isText1Animated && !this.state.isText1AnimatedTimeout){
    //   this.state.isText1AnimatedTimeout=true;
    //   setTimeout(() => {
    //     this.state.isText1Animated = true;
    //     const element = document.querySelector(".rndNewN-paragraph");
    //     this.animateLetters(element, 0.03);
    //     this.state.disableScrolling = true;
    //     setTimeout(() => {
    //       this.state.disableScrolling = false;
    //     }, 6000);
    //   }, 3000);
    // }
    // const element = document.querySelector(".rndNewN-paragraph");
    // this.animateLetters(element, 0.03);
    // this.state.disableScrolling = true;
    // setTimeout(() => {
    //   this.state.disableScrolling = false;
    // }, 6000);
  }

  handleRouteNextPage = () => {
    setTimeout(() => {
      history.push("/selection-page");
    }, 500);
  };

  ///////////////
  handleMouseDown = (e) => {
    this.setState({
      startY: e.clientY,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      } else if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleMouseUp = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  handleTouchStart = (e) => {
    this.setState({
      startY: e.touches[0].clientY,
    });
  };

  handleTouchMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.touches[0].clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      } else if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleOpeningNextPage() {
    console.log(this.state.disableScrolling);
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 0) {
        this.setBulletValue(50);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 50) {
        this.setBulletValue(100);
        this.setState({
          activeText: 0,
        });

        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          window.removeEventListener("wheel", this.throttledScroll);
          //window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
        }, 1000);
        setTimeout(() => {
          history.push("/pixels-2-pixels");
        }, 2501);
      }
    }
  }

  handleOpeningNextPageUP() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });
      if (this.state.value == 50) {
        this.setBulletValue(0);
        this.setState({
          activeText: 1,
        });
      }
    }
  }

  setBulletValue = (val) => {
    this.setState((prevState, prevProps) => {
      if ((!prevState.value != val || val == 0) && !prevState.disableScrolling) {
        return { value: val };
      }
      return null;
    });

    this.setState((prevState, prevProps) => {
      if (!prevState.disableScrolling) {
        return { disableScrolling: true };
      }
      return null;
    });
  };

  handleTouchEnd = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };
  //////////

  // playSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("rnd1");
  // }

  // stopSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("nan");
  //   setSounds.setStopSounds("rnd1");
  // }

  supportsAlphaChannel() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("safari") === -1 || userAgent.indexOf("chrome") > -1;
  }

  handleClickCircle(bulletValue, activeText) {
    if (isPhone()) {
      if (!this.state.disableScrolling) {
        this.setBulletValue(bulletValue);
        this.setState({
          activeText: activeText,
        });
      }
    }
  }

  render() {
    const { text2, isText2Animated, shouldFadeOut1, shouldFadeOut2, shouldFadeOut3, value } = this.state;
    const { isMobile, activeId } = this.state;
    return (
      <div className="research-and-development" id="research-and-development">
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div className="pipe-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={value}
                onChange={this.handleChange}
                className="pipe-input"
              />
              <div className="pipe"></div>
              <div className="fill" style={{ height: `${value}%` }} />
              <div
                className="pulsating-circle"
                style={{ top: `${value}%` }}
                // onMouseDown={this.handleMouseDown}
                // onMouseMove={this.handleMouseMove}
                // onMouseUp={this.handleMouseUp}
                // onTouchStart={this.handleTouchStart}
                // onTouchMove={this.handleTouchMove}
                // onTouchEnd={this.handleTouchEnd}
              />
              <div className="input-start">
                <p>Start</p>
              </div>
              <div className="input-end">
                <p>Next</p>
              </div>
              <div className="line-zero-academy" onClick={() => this.handleClickCircle(0, 1)}></div>
              <div className="line-first-academy" onClick={() => this.handleClickCircle(50, 2)}></div>

              <div className="line-third-academy" onClick={() => this.handleClickCircle(100, 0)}></div>
            </div>
          </div>
        </div>

        <NewContact />
        <Logo
          url={"/assets/sajtLogos/Harmonity_R&D_Logo_White_Transparent.png"}
          passedFunction={this.handleRouteNextPage}
        />
        <ResearchAndDevelopment3Three antialias id="my-canvas" textures={this.props.textures} />

        <div className="rndVideoDiv rndVideoDivdesktop">
          {!isPhone() && (
            <div className="rndVideoDiv rndVideoDivdesktop">
              {this.supportsAlphaChannel() && (
                <video
                  id="video-player"
                  playsInline
                  muted
                  loop
                  autoPlay
                  width={setCanvasWidth}
                  height={setCanvasHeight}
                  src={videoFile}
                />
              )}
            </div>
            // <RnDVideoChromaEffect
            //   videoFile={videoFile}
            //   setCanvasWidth={setCanvasWidth}
            //   setCanvasHeight={setCanvasHeight}
            // />
          )}
        </div>

        <ResearchAndDevelopment2Three
          antialias
          id="my-canvas"
          handleMaskFade={this.props.handleMaskFade}
          textures={this.props.textures}
          models={this.props.models}
        />

        <TextBox
          activeText={this.state.activeText}
          activeTextContent={rndTetxts[this.state.activeText]}
          toggleActiveText={this.toggleActiveText}
        />

        <Pictograms
          toggleActiveText={this.toggleActiveText}
          activeText={this.state.activeText}
          pictograms={rndPictogram}
          bulletValue={this.setBulletValue}
          pageId={this.state.pageId}
        />

        {/* <ResearchAndDevelopment2Three antialias id="my-canvas"/> */}
        {/* <TestPageGrass antialias id="my-canvas"/> */}
        {/* <div style={{
          position: "relative"
        }}>
          <div className="rndNewN-texts">
            <div className="rndNewN-box">
              <p className={`rndNewN-paragraph ${shouldFadeOut1 ? 'fade-out-paragraph' : ''}`}>
                {this.state.texts[1].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText1Animated ? 'animated' : ''}`}>{char}</span>
                ))}
              </p>
            </div>
          </div>
          <div className="rndNewN-texts2">
            <div className="rndNewN-box2">
              <p className={`rndNewN-paragraph2 ${shouldFadeOut2 ? 'fade-out-paragraph' : ''}`}>{
                this.state.texts[2].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText2Animated ? 'animated' : ''}`}>{char}</span>
                ))}</p>
            </div>
          </div>
          <div className="rndNewN-texts3">
            <div className="rndNewN-box3">
              <p className={`rndNewN-paragraph3 ${shouldFadeOut3 ? 'fade-out-paragraph' : ''}`}>{
                this.state.texts[3].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText3Animated ? 'animated' : ''}`}>{char}</span>
                ))}</p>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

//ResearchAndDevelopment.contextType = OnceContext;

export function startScreen() {
  videoRD = document.getElementById("video-r-and-d");
  videoRDRevert = document.getElementById("video-r-and-d-r");

  if (!videoRD || !videoRDRevert || videoRD.readyState !== 4 || videoRDRevert.readyState !== 4) {
    throw Error("Problem with video resource");
  }
  if (isDesktop()) {
  }
}
